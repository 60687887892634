import React, { useContext } from 'react';
import { NavItemType } from '../types/menu';
import {
  ToolOutlined,
  SettingOutlined,
  UserSwitchOutlined,
  BankOutlined,
  ShopOutlined,
  GlobalOutlined,
  AppstoreOutlined,
  ArrowRightOutlined,
  CalculatorOutlined,
  AreaChartOutlined,
  FileTextOutlined,
  PicLeftOutlined,
  HomeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { AbilityContext } from 'Navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
/* PAGES */
const UsersPage = React.lazy(() => import('view/pages/definitions/user-roles/users'));
const RolesPage = React.lazy(() => import('view/pages/definitions/user-roles/roles'));
const PaymentMethodsPage = React.lazy(() => import('view/pages/definitions/account-features/payment-methods'));
const limitTypesPage = React.lazy(() => import('view/pages/definitions/account-features/limit-types'));
const CustomersPage = React.lazy(() => import('view/pages/definitions/customers'));
const CustomersPaymentMethodsPage = React.lazy(() => import('view/pages/definitions/customerPaymentMethods'));
const SuppliersPaymentMethodsPage = React.lazy(() => import('view/pages/definitions/supplierPaymentMethods'));
const AccountsPage = React.lazy(() => import('view/pages/definitions/accounts'));
const VirementsPage = React.lazy(() => import('view/pages/definitions/account-features/virements'));
const IesTypes = React.lazy(() => import('view/pages/definitions/IesTypes'));
const Ies = React.lazy(() => import('view/pages/definitions/account-features/ies'));
const IesReport = React.lazy(() => import('view/pages/reports/ies'));
// const Deposits = React.lazy(() => import('view/pages/definitions/deposits'));
// const Withdrawals = React.lazy(() => import('view/pages/definitions/withdrawals'));
const CustomerDeposits = React.lazy(() => import('view/pages/definitions/customerDeposits'));
const CustomerDashboard = React.lazy(() => import('view/pages/definitions/customerDashboard'));
const ProvidersPage = React.lazy(() => import('view/pages/definitions/providers'));
const LesseesPage = React.lazy(() => import('view/pages/definitions/lessees'));
const Dashboard = React.lazy(() => import('view/pages/dashboard'));
const Currents = React.lazy(() => import('view/pages/currentAccounts'));
const IdentifyCurrentAccountsPage = React.lazy(() => import('view/pages/definitions/identifyCurrentAccounts'));
const MailDepositsPage = React.lazy(() => import('view/pages/definitions/ppiDeposits/mailDeposits'));
const ApiDepositsPage = React.lazy(() => import('view/pages/definitions/ppiDeposits/apiDeposits'));
const PaparaIbanWithdrawalsPage = React.lazy(() => import('view/pages/definitions/paparaWithdrawals/ibanWithdrawals'));
const PaparaApiWithdrawalsPage = React.lazy(() => import('view/pages/definitions/paparaWithdrawals/apiWithdrawals'));
const RiskLimitsPage = React.lazy(() => import('view/pages/definitions/riskLimits'));

type RouteType = {
  path: string;
  element: React.FC;
};

const useMenuItems: () => { items: NavItemType[]; routes: RouteType[] } = () => {
  const abilities = useContext(AbilityContext);
  const { userType } = useSelector((state: RootState) => state.auth);

  const canShowDefinitions =
    abilities.can('show', 'authManagement') ||
    abilities.can('show', 'accountFeatures') ||
    abilities.can('show', 'entityManagement') ||
    abilities.can('show', 'currentAccounts') ||
    abilities.can('show', 'provider') ||
    abilities.can('show', 'iESTypes');

  const canShowAuthManagement = abilities.can('show', 'authManagement');
  const canShowAccountFeatures = abilities.can('show', 'accountFeatures');
  const canShowEntities = abilities.can('show', 'entityManagement');

  const customerItems: NavItemType = {
    id: 'definitions',
    type: 'group',
    children: [
      {
        id: 'customerDashboard',
        title: 'Dashboard',
        caption: 'Dashboard',
        type: 'item',
        icon: AppstoreOutlined,
        url: '/',
        element: CustomerDashboard
      },
      {
        id: 'customerDeposits',
        title: 'Yatırımlar',
        caption: 'Yatırımlar',
        type: 'item',
        icon: ArrowRightOutlined,
        url: '/customer/deposits',
        element: CustomerDeposits
      }
    ]
  };

  const adminItems: NavItemType = {
    id: 'definitions',
    type: 'group',
    children: [
      abilities.can('show', 'reportLevel1')
        ? {
            id: 'dashboard',
            title: 'Dashboard',
            caption: 'Dashboard',
            type: 'item',
            icon: AppstoreOutlined,
            url: '/',
            element: Dashboard
          }
        : {},
      abilities.can('show', 'account')
        ? {
            id: 'ppiAccounts',
            title: 'PPI Hesap İşlemleri',
            caption: 'PPI Hesap İşlemleri',
            type: 'item',
            icon: BankOutlined,
            url: '/ppi-accounts',
            element: AccountsPage
          }
        : {},
      abilities.can('show', 'virements')
        ? {
            id: 'ppiDeposits',
            title: 'PPI Yatırım İşlemleri',
            type: 'collapse',
            icon: CalculatorOutlined,
            children: [
              abilities.can('show', 'virements')
                ? {
                    id: 'mailDepositNotifications',
                    title: 'Mail Yatırım Bildirimleri',
                    caption: 'Mail Yatırım Bildirimleri',
                    type: 'item',
                    url: '/ppi-deposit/mail',
                    element: MailDepositsPage
                  }
                : {},
              abilities.can('show', 'transactionIES')
                ? {
                    id: 'apiDeposits',
                    title: 'Api Talepleri',
                    caption: 'Api Talepleri',
                    type: 'item',
                    url: '/ppi-deposit/api',
                    element: ApiDepositsPage
                  }
                : {}
            ]
          }
        : {},
      abilities.can('show', 'virements')
        ? {
            id: 'papara',
            title: 'Papara Çekim İşlemleri',
            type: 'collapse',
            icon: CalculatorOutlined,
            children: [
              abilities.can('show', 'virements')
                ? {
                    id: 'ibanWithdraws',
                    title: 'İban Çekim Bildirimleri',
                    caption: 'İban Çekim Bildirimleri',
                    type: 'item',
                    url: '/papara-withdraws/iban',
                    element: PaparaIbanWithdrawalsPage
                  }
                : {},
              abilities.can('show', 'virements')
                ? {
                    id: 'apiWithdraws',
                    title: 'Api Çekim Talepleri',
                    caption: 'Api Çekim Talepleri',
                    type: 'item',
                    url: '/papara-withdraws/api',
                    element: PaparaApiWithdrawalsPage
                  }
                : {},
              abilities.can('show', 'virements')
                ? {
                    id: 'paparaCustomerRiskLimits',
                    title: 'Müşteri Risk Limitleri',
                    caption: 'Müşteri Risk Limitleri',
                    type: 'item',
                    url: '/papara-withdraws/customer-risk',
                    element: Ies
                  }
                : {}
            ]
          }
        : {},
      abilities.can('show', 'virements') || abilities.can('show', 'transactionIES') // @todo
        ? {
            id: 'riskLimits',
            title: 'Risk Limitleri',
            caption: 'Risk Limitleri',
            type: 'item',
            icon: WarningOutlined,
            url: '/riskLimits',
            element: RiskLimitsPage
          }
        : {},
      abilities.can('show', 'virements') || abilities.can('show', 'transactionIES')
        ? {
            id: 'accounting',
            title: 'Muhasebe İşlemleri',
            type: 'collapse',
            icon: CalculatorOutlined,
            children: [
              abilities.can('show', 'virements')
                ? {
                    id: 'virements',
                    title: 'Virmanlar',
                    caption: 'Virmanlar',
                    type: 'item',
                    url: '/accountFeatures/virements',
                    element: VirementsPage
                  }
                : {},
              abilities.can('show', 'transactionIES')
                ? {
                    id: 'serviceIncomeExpense',
                    title: 'Hizmet/Gelir/Gider',
                    caption: 'Hizmet/Gelir/Gider',
                    type: 'item',
                    url: '/accountFeatures/ies',
                    element: Ies
                  }
                : {}
            ]
          }
        : {},
      abilities.can('show', 'currentAccounts')
        ? {
            id: 'currentAccount',
            title: 'Cariler',
            caption: 'Cariler',
            type: 'item',
            icon: FileTextOutlined,
            url: '/currents',
            element: Currents
          }
        : {},
      abilities.can('show', 'reportLevel1') || abilities.can('show', 'reportLevel2') || abilities.can('show', 'reportLevel3')
        ? {
            id: 'reports',
            title: 'Raporlar',
            type: 'collapse',
            icon: AreaChartOutlined,
            children: [
              abilities.can('show', 'reportLevel1')
                ? {
                    id: 'serviceIncomeExpenseReports',
                    title: 'Hizmet/Gelir/Gider Raporları',
                    caption: 'Hizmet/Gelir/Gider Raporları',
                    type: 'item',
                    url: '/reports/ies',
                    element: IesReport
                  }
                : {}
            ]
          }
        : {},
      canShowDefinitions
        ? {
            id: 'definitions',
            title: 'Tanımlamalar',
            type: 'collapse',
            icon: ToolOutlined,

            children: [
              canShowAuthManagement
                ? {
                    id: 'usersAndPermissions',
                    title: 'Kullanıcılar ve Roller',
                    type: 'collapse',
                    icon: UserSwitchOutlined,
                    children: [
                      {
                        id: 'users',
                        title: 'Kullanıcılar',
                        caption: 'Kullanıcılar',
                        type: 'item',
                        url: '/usersAndPermissions/users',
                        element: UsersPage
                      },
                      {
                        id: 'roles',
                        title: 'Roller',
                        caption: 'Roller',
                        type: 'item',
                        url: '/usersAndPermissions/roles',
                        element: RolesPage
                      }
                    ]
                  }
                : {},
              canShowAccountFeatures
                ? {
                    id: 'accountFeatures',
                    title: 'Hesap Özellikleri',
                    type: 'collapse',
                    icon: SettingOutlined,
                    children: [
                      {
                        id: 'paymentMethods',
                        title: 'Yatirim Tipleri',
                        caption: 'Yatirim Tipleri',
                        type: 'item',
                        url: '/accountFeatures/paymentMethods',
                        element: PaymentMethodsPage
                      },
                      {
                        id: 'limitTypes',
                        title: 'Limit Tipleri',
                        caption: 'Limit Tipleri',
                        type: 'item',
                        url: '/accountFeatures/limitTypes',
                        element: limitTypesPage
                      }
                    ]
                  }
                : {},
              canShowEntities
                ? {
                    id: 'customers',
                    title: 'Müşteriler',
                    type: 'item',
                    url: '/identify/customers',
                    target: false,
                    icon: ShopOutlined,
                    element: CustomersPage
                  }
                : {},
              abilities.can('show', 'provider')
                ? {
                    id: 'providers',
                    title: 'Providerlar',
                    type: 'item',
                    url: '/identify/providers',
                    target: false,
                    icon: GlobalOutlined,
                    element: ProvidersPage
                  }
                : {},
              abilities.can('show', 'provider') // @todo
                ? {
                    id: 'lessees',
                    title: 'Kiracılar',
                    type: 'item',
                    url: '/identify/lessees',
                    target: false,
                    icon: HomeOutlined,
                    element: LesseesPage
                  }
                : {},
              abilities.can('show', 'currentAccounts')
                ? {
                    id: 'currentAccounts',
                    title: 'Cariler',
                    type: 'item',
                    url: '/identify/currents',
                    target: false,
                    icon: FileTextOutlined,
                    element: IdentifyCurrentAccountsPage
                  }
                : {},
              abilities.can('show', 'iESTypes')
                ? {
                    id: 'iesTypes',
                    title: 'Hizmet/Gelir/Gider Tipleri',
                    type: 'item',
                    url: '/identify/iesTypes',
                    target: false,
                    icon: PicLeftOutlined,
                    element: IesTypes
                  }
                : {}
            ]
          }
        : {}
    ]
  };

  function getChildrenWithUrlAndElement(data: NavItemType[]) {
    let result: RouteType[] = [];
    function recurse(items: NavItemType[]) {
      for (let item of items) {
        if (item.url && item.element) {
          result.push({ path: item.url, element: item.element });
        }
        if (item.children) {
          recurse(item.children);
        }
      }
    }
    recurse(data);
    return result;
  }

  const adminSubPages: NavItemType = abilities.can('show', 'entityManagementAdvance')
    ? {
        id: 'definitions',
        type: 'group',
        children: [
          {
            id: 'customerPaymentMethods',
            title: 'CustomerPaymentMethods',
            caption: 'CustomerPaymentMethods',
            type: 'item',
            icon: AppstoreOutlined,
            url: '/customers/paymentMethod/:id',
            element: CustomersPaymentMethodsPage
          },
          {
            id: 'supplierPaymentMethods',
            title: 'SupplierPaymentMethods',
            caption: 'SupplierPaymentMethods',
            type: 'item',
            icon: AppstoreOutlined,
            url: '/suppliers/paymentMethod/:id',
            element: SuppliersPaymentMethodsPage
          }
        ]
      }
    : {};

  const userMenus = userType === 'customer' ? customerItems : userType === 'guvenliODE' ? adminItems : {};
  const subPages = userType === 'guvenliODE' ? adminSubPages : {};
  return {
    items: [userMenus],
    routes: getChildrenWithUrlAndElement([userMenus, subPages])
  };
};

export default useMenuItems;
